<template>
    <div class='agreement_document'>
        <div class="vue_title">协议文件</div>
        <div class="tabs">
            <div class="tabs-item" :class="{active:item.value==tabIndex}" v-for="item in tabs" :key="item.value" @click="changeTabs(item.value)">{{ item.label }}</div>
        </div>
        <div class="height" v-loading="loading">
            <div class="fwb" v-if="tabIndex=='article_privacy'">
                <div class="edit">
                    <div style="border: 1px solid #DCDFE6;">
                        <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor1" :defaultConfig="toolbarConfig" :mode="mode" />
                        <Editor style="height: 500px; width: 100%; overflow-y: hidden;" v-model="privacyContent" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated1" />
                    </div>
                </div>
            </div>
            <div class="fwb" v-if="tabIndex=='article_service'">
                <div class="edit">
                    <div style="border: 1px solid #DCDFE6;">
                        <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor2" :defaultConfig="toolbarConfig" :mode="mode" />
                        <Editor style="height: 500px; width: 100%; overflow-y: hidden;" v-model="serviceContent" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button size="small" class="btnBgColor_blue btn" :loading="btnLoading" @click="postConfig">保存</el-button>
        </div>
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    components: {
        Editor,
        Toolbar
    },
    data() {
        return {
            tabs: [
                { label: '服务协议', value: 'article_service', },
                { label: '隐私政策', value: 'article_privacy', },
            ],
            loading: false,
            btnLoading: false,
            tabIndex: 'article_service',
            privacyContent: "",
            serviceContent: "",
            editor1: null,
            editor2: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 继续其他菜单配置...
                }
            },
            mode: 'default', // or 'simple'
        }
    },
    created() {
        this.getConfig()
    },
    methods: {
        // 获取协议
        getConfig() {
            let obj = {
                name: this.tabIndex
            }
            this.loading = true
            this.$articleApi.getMiniConfigs(obj).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    if (this.tabIndex == 'article_privacy') {
                        this.privacyContent = res.result.content
                    } else if (this.tabIndex == 'article_service') {
                        this.serviceContent = res.result.content
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 修改协议
        postConfig() {
            if (this.tabIndex == 'article_privacy') {
                var obj = {
                    name: this.tabIndex,
                    content: this.privacyContent,
                }
            } else if (this.tabIndex == 'article_service') {
                var obj = {
                    name: this.tabIndex,
                    content: this.serviceContent,
                }
            }
            this.btnLoading = true
            this.$articleApi.saveMiniConfigs(obj).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    this.$succMsg('提交成功')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 切换
        changeTabs(val) {
            this.tabIndex = val
            this.getConfig()
        },
        // 隐私政策
        onCreated1(editor) {
            this.editor1 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        // 服务协议
        onCreated2(editor) {
            this.editor2 = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        // 富文本上传图片
        update(file, insertFn) {
            var that = this;
            let files = event.target.files[0];
            let form = new FormData();
            form.append("file", files);
            that.$publicApi.uploadFile(form).then(res => {
                if (res.code == 1000) {
                    console.log(res)
                    insertFn(res.result.url, file.name, res.result.url)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
    beforeDestroy() {
        const editor1 = this.editor1
        if (editor1 == null) return
        editor1.destroy() // 组件销毁时，及时销毁编辑器
        const editor2 = this.editor2
        if (editor2 == null) return
        editor2.destroy() // 组件销毁时，及时销毁编辑器
    },
}
</script>
<style lang='scss' scoped>
.agreement_document {
    .header {
        width: 100%;
        height: 60px;
        border-bottom: 2px solid #e7e7e7;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 60px;
        padding-left: 20px;
    }
    .tabs {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        background: #f7f7f7;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        display: flex;
        .tabs-item {
            width: 121px;
            text-align: center;
            line-height: 40px;
            height: 40px;
            font-size: 14px;
            color: #666666;
            cursor: pointer;
        }
        .tabs-item.active {
            color: #2e4bf2;
            background: #ffffff;
            border-bottom: 1px solid #ffffff;
        }
    }
}
.fwb {
    width: 100%;
    padding: 20px;
    .edit {
        width: 1400px;
        height: 600px;
    }
}
.height {
    height: calc(100vh - 250px);
}
.bottom-btn {
    border-top: 1px solid #e7e7e7;
    padding-top: 15px;
    padding-left: 20px;
    .btn {
        width: 86px;
    }
}
</style>